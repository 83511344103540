import { Mutation, Action } from 'vuex-module-decorators';
import { InjectModule, InjectVuexModule } from 'vue-di/vuex';
import { Resolve } from 'vue-di';
import SessionModule from './session';
import { Settings } from '~/services/settings';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { EdgeApi } from '@trialcard/apigateway.client';

@InjectModule({ stateFactory: true }, module)
export default class PrescriberModule extends InjectVuexModule {
    @Resolve
    public settings!: Settings;

    @Resolve
    edgeApi!: EdgeApi;

    @Resolve
    public session!: SessionModule;

    public prescriberData = {
        firstName: '' as string,
        lastName: '' as string,
        siteName: '' as string,
        phone: '' as string,
        preferredContact: '' as string,
        state: '' as string,
        addressOne: '' as string,
        addressTwo: '' as string,
        fullAddress: '' as string,
        city: '' as string,
        zip: '' as string,
        officeContactEmail: '' as string,
        officeContactFax: '' as string,
        npi: '' as string,
    };

    @Mutation
    public updatePrescriberData(newPrescriberData: any) {
        this.prescriberData = newPrescriberData;
    }

    @Mutation
    public resetPrescriber() {
        this.prescriberData = {
            firstName: '',
            lastName: '',
            siteName: '',
            phone: '',
            preferredContact: '',
            state: '',
            addressOne: '',
            addressTwo: '',
            fullAddress: '',
            city: '',
            zip: '',
            officeContactEmail: '',
            officeContactFax: '',
            npi: '',
        };
    }
    @Action({})
    async getPrescriberInfo(npiObj: any) {
        try {
            const axios: AxiosInstance = (this.edgeApi as any).axios;
            const axiosRequest: AxiosRequestConfig = {
                url: 'edge/npi/v1/npi/prescriberSearch',
                method: 'POST',
                data: npiObj,
                headers: {
                    'x-program-id': this.settings.programId!,
                },
            };
            await Promise.all([
                (this.edgeApi as any).authentications.ApiKey.applyToRequest(axiosRequest),
                (this.edgeApi as any).authentications.Bearer.applyToRequest(axiosRequest),
                (this.edgeApi as any).authentications.Token.applyToRequest(axiosRequest),
            ]);
            const { data } = await axios.request(axiosRequest);
            return data;
        } catch {
            return false;
        }
    }
}
