import { Mutation } from 'vuex-module-decorators';
import { InjectModule, InjectVuexModule } from 'vue-di/vuex';

@InjectModule({ stateFactory: true }, module)
export default class RouteModule extends InjectVuexModule {
    previousRoute = '';
    allowedRoutes = ['/', '/health-care-professional/eligibility', '/error'];

    @Mutation
    setRoute(previousRoute: string) {
        this.previousRoute = previousRoute;
    }

    @Mutation
    updateAllowedRoutes(route: string) {
        this.allowedRoutes.push(route);
    }

    @Mutation
    resetAllowedRoutes() {
        this.allowedRoutes = ['/', '/health-care-professional/eligibility', '/error'];
    }
}
