



















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Resolve } from 'vue-di';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { faFacebook, faYoutube, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import LayoutModule from '~/store/layout';

@Component<Footer>({
    icons: {
        faPlus,
        faFacebook,
        faInstagram,
        faTwitter,
        faYoutube,
    },
})
export default class Footer extends Vue {
    @Resolve
    public layout!: LayoutModule;
    @Prop()
    public brkPoints!: object;
    @Prop({ default: false })
    public isFixed!: boolean;

    public get footerLogoSrc() {
        return this.layout.configuration.images.footer || this.$settings.url(this.$settings.footerLogo);
    }
}
