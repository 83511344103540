




































import Vue from 'vue';
import Component from 'vue-class-component';
import { Resolve } from 'vue-di';
import LayoutModule from '~/store/layout';
@Component<ImportantSafetyInformation>({
    layout: 'default',
})
export default class ImportantSafetyInformation extends Vue {
    @Resolve
    public layout!: LayoutModule;
}
