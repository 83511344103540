// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".eligibility-message p{font-family:Red Hat Display,sans-serif;font-weight:400;font-size:18px;line-height:28px;color:#0f0f0f}.eligibility-criteria{font-size:24px;font-family:Red Hat Display,sans-serif;font-weight:600;line-height:32px}.eligibility-btn{height:150px}.eligibility-header{font-family:Montserrat,sans-serif;font-weight:600;font-size:30px;line-height:40px}.eligibility-header__sub{font-weight:400;font-size:18px;line-height:25px;color:#403f3f}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#64b5f6",
	"secondaryColor": "#003c60",
	"accentColor": "#c41230"
};
module.exports = exports;
