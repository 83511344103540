export class Settings {
    public constructor(settings: any) {
        Object.assign(this, settings);
    }
}
export interface Settings {
    base: string;
    url(url: string): string;
    name: string;
    author: string;
    description: string;
    minDate: string;
    mainMenu: Array<{ icon: [string, string]; title: string; to: string; exact: boolean }>;
    errorPages: {
        default: { icon: [string, string]; title: string; message: string; statusCode: number };
        [code: string]: { icon: [string, string]; title: string; message: string; statusCode: number };
    };
    card: string;
    headerLogo: string;
    headerLogoLeft: string;
    headerLogoRight: string;
    footerLogo: string;
    cardArtBack: string;
    buttonIconProfessional: string;
    buttonIconPatient: string;
    safetyInfoBg: string;
    cardTypeQuestionId: string;
    cardTypeAnswerId: string;
    programId: string;
    abbottPatientSiteUrl: string;
    tenantId: string;
    sourceId: string;
    surveyId: string;
    cgmQuestionId: string;
    cgmLibre2AnswerId: string;
    cgmLibre3AnswerId: string;
    cgmLibre3PlusAnswerId: string;
    prescriptionQuestionId: string;
    prescriptionYesAnswerId: string;
    prescriptionNoAnswerId: string;
    usResidentQuestionId: string;
    usResidentYesAnswerId: string;
    usResidentNoAnswerId: string;
    ageQuestionId: string;
    ageYesAnswerId: string;
    ageNoAnswerId: string;
    diabetesTypeQuestionId: string;
    diabetesType1AnswerId: string;
    diabetesType2AnswerId: string;
    diabetesGestationalAnswerId: string;

    // pre-existing
    eligibilityFederalQuestion: string;
    eligibilityFederalAnswer: string;
    eligibilityCitizenQuestion: string;
    eligibilityCitizenAnswer: string;
    eligibilityAgeQuestion: string;
    eligibilityAgeAnswer: string;
    triangleUp: string;
    triangleDown: string;
    lockEnabled: string;
    lockPassword: string;
}
