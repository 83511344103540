

































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Resolve } from 'vue-di';
import { required } from 'vuelidate/lib/validators';
import { CommonTools } from '../tools/commonTools';
import { Validate } from '~/validation/Validate';
import EligibilityModule from '~/store/eligibility';
import NextBack from '~/components/NextBack.vue';
import { Settings } from '~/services/settings';
import LayoutModule from '~/store/layout';
import SurveyModule from '~/store/survey';
import RouteModule from '~/store/routes';

@Component<EligibilityComponent>({
    layout: 'default',
    components: {
        NextBack,
    },
})
export default class EligibilityComponent extends Vue {
    @Resolve
    public eligibility!: EligibilityModule;

    @Resolve
    public settings!: Settings;

    @Resolve
    public layout!: LayoutModule;

    @Resolve
    public survey!: SurveyModule;

    @Resolve
    public route!: RouteModule;

    public patientIsNotEligible = false;
    public doValidate() {
        this.$v.$touch();

        if (this.$v.$invalid) {
            return false;
        }

        return true;
    }

    public get isInvalid() {
        return this.$v.$invalid && this.$v.$dirty;
    }

    public doReset() {
        this.$v.$reset();
    }

    public get termsAndConditionsUrl() {
        const env = (window as any).$nuxt.context.env.EnvironmentName;
        if (env === 'development') {
            return '/terms-and-conditions';
        } else {
            return '/abbott/terms-and-conditions';
        }
    }

    public handleBack() {
        const name = CommonTools.getRouteName('eligibility', this.layout.configuration.pages, true);
        this.$router.push({ name });
    }

    public get isPatientEligible() {
        return (
            (Number(this.glucoseMonitoring) === Number(this.settings.cgmLibre2AnswerId) ||
                Number(this.glucoseMonitoring) === Number(this.settings.cgmLibre3AnswerId) ||
                Number(this.glucoseMonitoring) === Number(this.settings.cgmLibre3PlusAnswerId)) &&
            Number(this.doesPatientLiveInUs) === Number(this.settings.usResidentYesAnswerId) &&
            Number(this.isPatient18OrOlder) === Number(this.settings.ageYesAnswerId) &&
            Number(this.prescriptionThroughKaiserOrFederalProgram) === Number(this.settings.prescriptionNoAnswerId) &&
            this.agreesToTerms1 &&
            this.agreesToTerms2
        );
    }

    @Validate({ required })
    public glucoseMonitoring = '';

    @Validate({ required })
    public prescriptionThroughKaiserOrFederalProgram = '';

    @Validate({ required })
    public doesPatientLiveInUs = '';

    @Validate({ required })
    public isPatient18OrOlder = '';

    @Validate({ required })
    public agreesToTerms1 = '';

    @Validate({ required })
    public agreesToTerms2 = '';

    public formMessages = {
        required: 'Required',
    };

    public consentProvided = false;

    public async submit() {
        if (!this.agreesToTerms1 || !this.agreesToTerms2 || !this.doValidate()) {
            this.$v.$touch();
            return;
        }

        this.eligibility.updateEligibilityData({
            cgmQuestion: this.glucoseMonitoring,
            prescriptionQuestion: this.prescriptionThroughKaiserOrFederalProgram,
            usResidentQuestion: this.doesPatientLiveInUs,
            ageQuestion: this.isPatient18OrOlder,
        });
        if (this.isPatientEligible) {
            // const name = CommonTools.getRouteName('eligibility', this.layout.configuration.pages);
            this.route.updateAllowedRoutes('/health-care-professional/patient-information');
            await this.$router.push({ path: '/health-care-professional/patient-information' });
        } else {
            this.patientIsNotEligible = true;
        }
    }

    mounted() {
        if (this.eligibility.eligibilityData && this.eligibility) {
            this.glucoseMonitoring = this.eligibility?.eligibilityData?.cgmQuestion;
            this.prescriptionThroughKaiserOrFederalProgram = this.eligibility?.eligibilityData?.prescriptionQuestion;
            this.doesPatientLiveInUs = this.eligibility?.eligibilityData?.usResidentQuestion;
            this.isPatient18OrOlder = this.eligibility?.eligibilityData?.ageQuestion;
        }
    }
}
