




































import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';
import { Resolve } from 'vue-di';
import LayoutModule from '../store/layout';

@Component({})
export default class NextBack extends Vue {
    @Resolve
    public layout!: LayoutModule;

    @Prop()
    public nextFunction!: Function;

    @Prop()
    public backFunction!: Function;

    @Prop({ default: () => ['Next', 'Back'] })
    public btnNames!: string[];

    @Prop({ default: () => false })
    public disabled!: boolean;

    get nextButton() {
        return this.btnNames[0];
    }

    get backButton() {
        return this.btnNames[1];
    }
}
