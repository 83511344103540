import { Mutation, Action } from 'vuex-module-decorators';
import { Resolve } from 'vue-di';
import { InjectModule, InjectVuexModule } from 'vue-di/vuex';
import { EdgePatientEnrollmentApi } from '@trialcard/apigateway.client/edgePatientEnrollmentApi';
import {
    EnterpriseCoordinatorAccountUpsearchRequestModel,
    UpsearchModelEnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel,
    UpsearchModelEnterpriseCoordinatorAccountServiceModelsAccountUpsearchModel,
    EnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel,
    EnterpriseCoordinatorSharedAddressUpsearchModel,
    EnterpriseCoordinatorSharedEmailAddressUpsearchModel,
    EnterpriseCoordinatorSharedPhoneUpsearchModel,
    UpsearchModelEnterpriseCoordinatorPayorServiceModelsPayorUpsearchModel,
    EnterpriseCoordinatorAccountServiceModelsAccountContactPreferenceModel,
    EnterpriseCoordinatorAccountServiceModelsAccountContactUpsearchModel,
    UpsearchModelEnterpriseCoordinatorPrescriberServiceModelsPrescriberUpsearchModel,
    EnterpriseCoordinatorPrescriberServiceModelsPrescriberUpsearchModel,
    UpsearchModelEnterpriseCoordinatorSiteServiceModelsSiteUpsearchModel,
    EnterpriseCoordinatorSiteServiceModelsSiteUpsearchModel,
} from '@trialcard/apigateway.models';
import { RoleType } from '@trialcard/enums';
import * as enums from '@trialcard/enums';

import { Moment } from 'moment';
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { EdgeEnrollmentApi } from '@trialcard/apigateway.client';
import { Settings } from '~/services/settings';

import SurveyModule from '~/store/survey';

import { rolling365 } from '~/tests/e2e/@data/shared/dates';
import { pharmacyMN } from '~/tests/e2e/@data/shared/memberNumbers';
import PatientModule from '~/store/patient';
import PrescriberModule from '~/store/prescriber';
import EligibilityModule from '~/store/eligibility';

@InjectModule({ stateFactory: true }, module)
export default class EnrollmentModule extends InjectVuexModule {
    @Resolve
    public patientEnrollmentApi!: EdgePatientEnrollmentApi;

    @Resolve
    public enrollmentApi!: EdgeEnrollmentApi;

    @Resolve
    public patient!: PatientModule;

    @Resolve
    public prescriber!: PrescriberModule;

    @Resolve
    public eligibility!: EligibilityModule;

    @Resolve
    public settings!: Settings;

    @Resolve
    public survey!: SurveyModule;

    isCareGiver: boolean | null = null;

    defaultPatientInformationData = {
        diabetesType: '' as string,
        firstName: '' as string,
        lastName: '' as string,
        dateOfBirth: null as Moment | null,
        addresses: [] as EnterpriseCoordinatorSharedAddressUpsearchModel[],
        emailAddresses: [] as EnterpriseCoordinatorSharedEmailAddressUpsearchModel[],
        phoneNumbers: [] as EnterpriseCoordinatorSharedPhoneUpsearchModel[],
        gender: null as enums.Gender | null | string,
    };

    defaultCareGiverInformationData = {
        firstName: '' as string,
        lastName: '' as string,
        addresses: [] as EnterpriseCoordinatorSharedAddressUpsearchModel[],
        emailAddresses: [] as EnterpriseCoordinatorSharedEmailAddressUpsearchModel[],
        phoneNumbers: [] as EnterpriseCoordinatorSharedPhoneUpsearchModel[],
    };

    defaultPrescriberData = [
        {
            model: {
                firstName: '',
                lastName: '',
                npi: '',
                accountRelationshipIsPrimary: true,
                prescriberLicenses: [],
                externalIds: [],
                addresses: [],
                phoneNumbers: [],
                emailAddresses: [],
            },
        },
    ] as UpsearchModelEnterpriseCoordinatorPrescriberServiceModelsPrescriberUpsearchModel[];

    defaultSiteData = [
        {
            model: {
                accountRelationshipIsPrimary: true,
                siteRoleType: RoleType.PrescriberSite,
                pointOfContacts: [],
                description: '',
                addresses: [
                    {
                        addressOne: '',
                        addressTwo: '',
                        zip: '',
                        city: '',
                        state: '',
                    },
                ],
                contacts: [],
                emailAddresses: [],
                externalIds: [],
                phoneNumbers: [],
                globalIds: [],
            },
        },
    ] as UpsearchModelEnterpriseCoordinatorSiteServiceModelsSiteUpsearchModel[];

    defaultPayorData = [
        {
            model: {
                payorId: (null as unknown) as number,
                payorType: (null as unknown) as number,
                accountRelationshipIsPrimary: true,
                payorGroup: '',
                payorName: '',
                webAddresses: [],
                addresses: [],
                phoneNumbers: [
                    {
                        phoneType: 1,
                        phoneNumber: '',
                        phonePurpose: 1,
                    },
                    {
                        phoneType: 3,
                        phoneNumber: '',
                        phonePurpose: 8,
                    },
                ],
                emailAddresses: [],
                contacts: [],
                payorBenefit: {
                    dateOfBirth: null as Moment | null,
                    policyHolderFirstName: '',
                    policyHolderLastName: '',
                    relationshipToPatient: undefined,
                    policyNumber: '',
                    planType: undefined,
                },
            },
        },
    ] as UpsearchModelEnterpriseCoordinatorPayorServiceModelsPayorUpsearchModel[];

    defaultContactPreferences = [] as EnterpriseCoordinatorAccountServiceModelsAccountContactPreferenceModel[];

    defaultMemberNumber = pharmacyMN.number;

    defaultExpirationDate = rolling365;

    memberNumber = this.defaultMemberNumber;
    expirationDate = this.defaultExpirationDate;
    patientInformationData = { ...this.defaultPatientInformationData };
    careGiverInformationData = { ...this.defaultCareGiverInformationData };
    prescriberData = [...this.defaultPrescriberData];
    siteData = [...this.defaultSiteData];
    payorData = [...this.defaultPayorData];
    contactPreferences = [...this.defaultContactPreferences];

    @Mutation
    public updatePatientInformation(newPatientInformation: typeof EnrollmentModule.prototype.patientInformationData) {
        this.patientInformationData = { ...this.patientInformationData, ...newPatientInformation };
    }

    @Mutation
    public async updatePrescribers(newPrescriberData: UpsearchModelEnterpriseCoordinatorPrescriberServiceModelsPrescriberUpsearchModel[]) {
        this.prescriberData = [...newPrescriberData];
    }

    @Mutation
    public async updateSites(newSites: UpsearchModelEnterpriseCoordinatorSiteServiceModelsSiteUpsearchModel[]) {
        this.siteData = [...newSites];
    }

    @Mutation
    public async updatePayors(newPayorData: UpsearchModelEnterpriseCoordinatorPayorServiceModelsPayorUpsearchModel[]) {
        this.payorData = [...newPayorData];
    }

    @Mutation
    public async updateCareGiverInformation(newCareGiverInformation: typeof EnrollmentModule.prototype.careGiverInformationData) {
        this.patientInformationData = { ...this.patientInformationData, ...newCareGiverInformation };
    }

    @Mutation
    public async updateContactPreferences(newContactPreferences: EnterpriseCoordinatorAccountServiceModelsAccountContactPreferenceModel[]) {
        this.contactPreferences = [...newContactPreferences];
    }

    @Mutation
    public async setMemberNumber(memberNumber: string) {
        this.memberNumber = memberNumber;
    }

    @Mutation
    public setExpirationDate(expirationDate: Moment) {
        this.expirationDate = expirationDate;
    }

    @Mutation
    public setIsCaregiver(isCareGiver: boolean | null) {
        this.isCareGiver = isCareGiver;
    }

    @Action({ rawError: true })
    public clearStore() {
        this.setMemberNumber(this.defaultMemberNumber);
        this.setExpirationDate(this.defaultExpirationDate);
        this.updatePatientInformation(this.defaultPatientInformationData);
        this.updateCareGiverInformation(this.defaultCareGiverInformationData);
        this.updatePrescribers(this.defaultPrescriberData);
        this.updateSites(this.defaultSiteData);
        this.updatePayors(this.defaultPayorData);
        this.updateContactPreferences(this.defaultContactPreferences);
    }

    @Action({ rawError: true })
    public clearEnrollment() {
        this.updatePatientInformation({ ...this.defaultPatientInformationData });
        this.setMemberNumber(this.defaultMemberNumber);
    }

    @Action({ rawError: true })
    public async submitToAPI() {
        try {
            const patient = {
                account: {
                    sourceId: parseInt(this.settings.sourceId),
                    patientData: {
                        canUpdate: true,
                        canInsert: true,
                        skipSearch: false,
                        model: {
                            firstName: this.patient.patientData.firstName,
                            lastName: this.patient.patientData.lastName,
                            phoneNumbers: [
                                this.structuredPhoneObjForPatient(this.patient.patientData.phone, 1),
                            ] as EnterpriseCoordinatorSharedPhoneUpsearchModel[],
                            emailAddresses:
                                this.patient.patientData?.email?.length > 0
                                    ? ([
                                          {
                                              emailAddressType: 0,
                                              address: this.patient.patientData.email,
                                          },
                                      ] as EnterpriseCoordinatorSharedEmailAddressUpsearchModel[])
                                    : [],
                            dateOfBirth: this.patient.patientData?.dateOfBirth,
                            gender: this.patient.patientData.gender,
                            addresses: [
                                {
                                    addressType: enums.AddressType.MAILINGADDRESS,
                                    addressOne: this.patient.patientData.addressOne,
                                    addressTwo: this.patient.patientData.addressTwo,
                                    city: this.patient.patientData.city,
                                    state: this.patient.patientData.state,
                                    zip: this.patient.patientData.zip,
                                },
                            ] as EnterpriseCoordinatorSharedAddressUpsearchModel[],
                            middleName: '',
                            suffix: '',
                            nickname: '',
                        } as EnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel,
                    } as UpsearchModelEnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel,
                    accountData: {
                        canUpdate: true,
                        canInsert: true,
                        skipSearch: false,
                        model: {
                            accountType: 1,
                            accountStatus: 1,
                            externalIds: [] as EnterpriseCoordinatorAccountServiceModelsAccountContactUpsearchModel[],
                            contacts: [] as EnterpriseCoordinatorAccountServiceModelsAccountContactUpsearchModel[],
                        },
                    } as UpsearchModelEnterpriseCoordinatorAccountServiceModelsAccountUpsearchModel,
                    prescriberData: [
                        {
                            canUpdate: false,
                            canInsert: true,
                            skipSearch: true,
                            model: {
                                accountRelationshipIsPrimary: true,
                                firstName: this.prescriber.prescriberData.firstName,
                                lastName: this.prescriber.prescriberData.lastName,
                                npi: this.prescriber.prescriberData.npi,
                                phoneNumbers: [
                                    {
                                        phoneNumber: this.formatPhoneForAPI(this.prescriber.prescriberData.phone),
                                        phonePurpose: enums.ContactInfoPurposeType.MAIN,
                                        phoneType: enums.ContactInfoType.MOBILE,
                                    },
                                ],
                                addresses: [
                                    {
                                        addressOne: this.prescriber.prescriberData.addressOne,
                                        addressTwo: this.prescriber.prescriberData.addressTwo,
                                        city: this.prescriber.prescriberData.city,
                                        state: this.prescriber.prescriberData.state,
                                        zip: this.prescriber.prescriberData.zip,
                                    },
                                ],
                            } as EnterpriseCoordinatorPrescriberServiceModelsPrescriberUpsearchModel,
                        } as UpsearchModelEnterpriseCoordinatorPrescriberServiceModelsPrescriberUpsearchModel,
                    ],
                    prescriptionData: [
                        {
                            daySupply: 14,
                            refills: 0,
                            quantity: 1,
                            directions: 'Apply one sensor every 14 days',
                            ndcId:
                                this.eligibility.eligibilityData.cgmQuestion === this.settings.cgmLibre2AnswerId
                                    ? 181271
                                    : this.eligibility.eligibilityData.cgmQuestion === this.settings.cgmLibre3AnswerId
                                    ? 192395
                                    : 206335,
                        },
                    ],
                    pharmacyData: [
                        {
                            canUpdate: false,
                            canInsert: true,
                            skipSearch: true,
                            model: {
                                pharmacyName: 'BestFit Pharmacy',
                                nabp: '',
                                npi: '9999999999',
                                pharmacyType: enums.PharmacyType.OTHER,
                                accountRelationshipIsPrimary: true,
                            },
                        },
                    ],
                    siteData: [
                        {
                            canUpdate: false,
                            canInsert: true,
                            skipSearch: true,
                            model: {
                                accountRelationshipIsPrimary: true,
                                siteRoleType: enums.RoleType.PrescriberSite,
                                description: this.prescriber.prescriberData.siteName,
                                pointOfContacts: [
                                    {
                                        preferredContactMethod:
                                            this.prescriber.prescriberData.preferredContact === 'email'
                                                ? 5
                                                : this.prescriber.prescriberData.preferredContact === 'fax'
                                                ? 3
                                                : 0,
                                        emailAddress: this.prescriber?.prescriberData?.officeContactEmail,
                                        phoneNumber:
                                            this.prescriber?.prescriberData?.phone?.length > 0
                                                ? this.formatPhoneForAPI(this.prescriber.prescriberData.phone)
                                                : '',
                                        faxNumber: this.formatPhoneForAPI(this.prescriber.prescriberData.officeContactFax),
                                    },
                                ],
                                phoneNumbers: [
                                    this.structuredPhoneObjForPrescriber(
                                        this.prescriber.prescriberData.officeContactFax,
                                        enums.ContactInfoType.FAX
                                    ),
                                ],
                                addresses: [
                                    {
                                        addressOne: this.prescriber.prescriberData.addressOne,
                                        addressTwo: this.prescriber.prescriberData.addressTwo,
                                        city: this.prescriber.prescriberData.city,
                                        state: this.prescriber.prescriberData.state,
                                        zip: this.prescriber.prescriberData.zip,
                                    },
                                ],
                            } as EnterpriseCoordinatorSiteServiceModelsSiteUpsearchModel,
                        },
                    ] as UpsearchModelEnterpriseCoordinatorSiteServiceModelsSiteUpsearchModel,
                    surveySessionData: [this.survey.sessionId] as number[],
                    payorData: [] as UpsearchModelEnterpriseCoordinatorPayorServiceModelsPayorUpsearchModel,
                } as EnterpriseCoordinatorAccountUpsearchRequestModel,
            };
            const axios: AxiosInstance = (this.patientEnrollmentApi as any).axios;
            const axiosRequest: AxiosRequestConfig = {
                url: '/edge/patientEnrollment/enrollPatient',
                method: 'POST',
                data: patient,
                headers: {
                    'x-program-id': this.settings.programId!,
                },
            };
            await Promise.all([
                (this.patientEnrollmentApi as any).authentications.ApiKey.applyToRequest(axiosRequest),
                (this.patientEnrollmentApi as any).authentications.Bearer.applyToRequest(axiosRequest),
                (this.patientEnrollmentApi as any).authentications.Token.applyToRequest(axiosRequest),
            ]);
            return await axios.request(axiosRequest);
        } catch (e) {
            console.log(e);
            return;
        }
    }

    @Action({ rawError: true })
    public async sendEnrollmentToEzScript(accountId: number) {
        try {
            const axios: AxiosInstance = (this.patientEnrollmentApi as any).axios;
            const axiosRequest: AxiosRequestConfig = {
                url: '/edge/transient/ezScript/sendEnrollment',
                method: 'POST',
                data: { accountId },
                headers: {
                    'x-program-id': this.settings.programId!,
                },
            };
            await Promise.all([
                (this.patientEnrollmentApi as any).authentications.ApiKey.applyToRequest(axiosRequest),
                (this.patientEnrollmentApi as any).authentications.Bearer.applyToRequest(axiosRequest),
                (this.patientEnrollmentApi as any).authentications.Token.applyToRequest(axiosRequest),
            ]);
            return await axios.request(axiosRequest);
        } catch (e) {
            console.error(e);
            return;
        }
    }

    @Action({ rawError: true })
    public async sendReminderToEzScript<T extends { memberId: string; groupId: string }>(data: T) {
        const { memberId, groupId } = data;
        try {
            const axios: AxiosInstance = (this.patientEnrollmentApi as any).axios;
            const axiosRequest: AxiosRequestConfig = {
                url: '/edge/transient/ezScript/sendReminder',
                method: 'POST',
                data: { memberId, groupId, reminderType: 'text', reminderName: 'WELCOME' },
                headers: {
                    'x-program-id': this.settings.programId!,
                },
            };
            await Promise.all([
                (this.patientEnrollmentApi as any).authentications.ApiKey.applyToRequest(axiosRequest),
                (this.patientEnrollmentApi as any).authentications.Bearer.applyToRequest(axiosRequest),
                (this.patientEnrollmentApi as any).authentications.Token.applyToRequest(axiosRequest),
            ]);
            return await axios.request(axiosRequest);
        } catch (e) {
            console.error(e);
            return;
        }
    }

    public structuredPhoneObjForPatient(phone: string, phonePurpose: number) {
        switch (phonePurpose) {
            case enums.ContactInfoPurposeType.MAIN:
                return {
                    phoneNumber: this.formatPhoneForAPI(phone),
                    phonePurpose: enums.ContactInfoPurposeType.MAIN,
                    phoneType: enums.ContactInfoType.MOBILE,
                };
            case enums.ContactInfoPurposeType.HOME:
                return {
                    phoneNumber: this.formatPhoneForAPI(phone),
                    phonePurpose: enums.ContactInfoPurposeType.HOME,
                    phoneType: enums.ContactInfoType.PHONE,
                };

            case enums.ContactInfoPurposeType.WORK:
                return {
                    phoneNumber: this.formatPhoneForAPI(phone),
                    phonePurpose: enums.ContactInfoPurposeType.WORK,
                    phoneType: enums.ContactInfoType.PHONE,
                };

            default:
                return;
        }
    }

    public structuredPhoneObjForPrescriber(phone: string, phoneType: number) {
        switch (phoneType) {
            case enums.ContactInfoType.FAX:
                return {
                    phoneNumber: this.formatPhoneForAPI(phone),
                    phonePurpose: enums.ContactInfoPurposeType.UNKNOWN,
                    phoneType: enums.ContactInfoType.FAX,
                };

            case enums.ContactInfoType.PHONE:
                return {
                    phoneNumber: this.formatPhoneForAPI(phone),
                    phonePurpose: enums.ContactInfoPurposeType.UNKNOWN,
                    phoneType: enums.ContactInfoType.PHONE,
                };

            default:
                return;
        }
    }

    formatPhoneForAPI(phoneNumber: string) {
        return phoneNumber?.replace(/\D/g, '');
    }
}
