




















import { Vue } from 'vue-property-decorator';
import { Component } from 'vue-property-decorator';
import EligibilityComponent from '~/components/PageComponents/EligibilityComponent.vue';

@Component({
    components: { EligibilityComponent },
})
export default class EligibilityDisclaimer extends Vue {}
