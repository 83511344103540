import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4a937ea6 = () => interopDefault(import('..\\pages\\error.vue' /* webpackChunkName: "pages_error" */))
const _2bbbc166 = () => interopDefault(import('..\\pages\\have-a-card.vue' /* webpackChunkName: "pages_have-a-card" */))
const _64c3545c = () => interopDefault(import('..\\pages\\have-a-card\\index.vue' /* webpackChunkName: "pages_have-a-card_index" */))
const _5c0628ea = () => interopDefault(import('..\\pages\\have-a-card\\enrollment.vue' /* webpackChunkName: "pages_have-a-card_enrollment" */))
const _36f092e6 = () => interopDefault(import('..\\pages\\have-a-card\\success.vue' /* webpackChunkName: "pages_have-a-card_success" */))
const _73df7f45 = () => interopDefault(import('..\\pages\\health-care-professional\\index.vue' /* webpackChunkName: "pages_health-care-professional_index" */))
const _b81d78a4 = () => interopDefault(import('..\\pages\\rejection.vue' /* webpackChunkName: "pages_rejection" */))
const _0900ce5f = () => interopDefault(import('..\\pages\\terms-and-conditions.vue' /* webpackChunkName: "pages_terms-and-conditions" */))
const _2c1b5d22 = () => interopDefault(import('..\\pages\\unlock.vue' /* webpackChunkName: "pages_unlock" */))
const _7acfdc4b = () => interopDefault(import('..\\pages\\upload-documents\\index.vue' /* webpackChunkName: "pages_upload-documents_index" */))
const _bdecfce4 = () => interopDefault(import('..\\pages\\errors\\400.vue' /* webpackChunkName: "pages_errors_400" */))
const _bdd0cde2 = () => interopDefault(import('..\\pages\\errors\\401.vue' /* webpackChunkName: "pages_errors_401" */))
const _bd986fde = () => interopDefault(import('..\\pages\\errors\\403.vue' /* webpackChunkName: "pages_errors_403" */))
const _bd7c40dc = () => interopDefault(import('..\\pages\\errors\\404.vue' /* webpackChunkName: "pages_errors_404" */))
const _54208662 = () => interopDefault(import('..\\pages\\errors\\500.vue' /* webpackChunkName: "pages_errors_500" */))
const _2e469440 = () => interopDefault(import('..\\pages\\health-care-professional\\eligibility.vue' /* webpackChunkName: "pages_health-care-professional_eligibility" */))
const _169f3497 = () => interopDefault(import('..\\pages\\health-care-professional\\patient-information.vue' /* webpackChunkName: "pages_health-care-professional_patient-information" */))
const _e9f6027c = () => interopDefault(import('..\\pages\\health-care-professional\\prescriber.vue' /* webpackChunkName: "pages_health-care-professional_prescriber" */))
const _c5766994 = () => interopDefault(import('..\\pages\\health-care-professional\\success.vue' /* webpackChunkName: "pages_health-care-professional_success" */))
const _66d263da = () => interopDefault(import('..\\pages\\patient\\patientSearch.vue' /* webpackChunkName: "pages_patient_patientSearch" */))
const _d21fbb12 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages_index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('#{BasePath}#/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/error",
    component: _4a937ea6,
    name: "error"
  }, {
    path: "/have-a-card",
    component: _2bbbc166,
    children: [{
      path: "",
      component: _64c3545c,
      name: "have-a-card"
    }, {
      path: "enrollment",
      component: _5c0628ea,
      name: "have-a-card-enrollment"
    }, {
      path: "success",
      component: _36f092e6,
      name: "have-a-card-success"
    }]
  }, {
    path: "/health-care-professional",
    component: _73df7f45,
    name: "health-care-professional"
  }, {
    path: "/rejection",
    component: _b81d78a4,
    name: "rejection"
  }, {
    path: "/terms-and-conditions",
    component: _0900ce5f,
    name: "terms-and-conditions"
  }, {
    path: "/unlock",
    component: _2c1b5d22,
    name: "unlock"
  }, {
    path: "/upload-documents",
    component: _7acfdc4b,
    name: "upload-documents"
  }, {
    path: "/errors/400",
    component: _bdecfce4,
    name: "errors-400"
  }, {
    path: "/errors/401",
    component: _bdd0cde2,
    name: "errors-401"
  }, {
    path: "/errors/403",
    component: _bd986fde,
    name: "errors-403"
  }, {
    path: "/errors/404",
    component: _bd7c40dc,
    name: "errors-404"
  }, {
    path: "/errors/500",
    component: _54208662,
    name: "errors-500"
  }, {
    path: "/health-care-professional/eligibility",
    component: _2e469440,
    name: "health-care-professional-eligibility"
  }, {
    path: "/health-care-professional/patient-information",
    component: _169f3497,
    name: "health-care-professional-patient-information"
  }, {
    path: "/health-care-professional/prescriber",
    component: _e9f6027c,
    name: "health-care-professional-prescriber"
  }, {
    path: "/health-care-professional/success",
    component: _c5766994,
    name: "health-care-professional-success"
  }, {
    path: "/patient/patientSearch",
    component: _66d263da,
    name: "patient-patientSearch"
  }, {
    path: "/",
    component: _d21fbb12,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
